import React from 'react';
import '@fontsource/poppins/700.css';
import '@fontsource/nunito/700.css';
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import { Box, Flex, Heading, IconButton, Link, useColorModeValue, VStack, useDisclosure, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef<HTMLButtonElement>(null);
  const navBg = useColorModeValue('gray.100', 'gray.900');
  const menuBg = useColorModeValue('gray.50', 'gray.800');
  const hoverBg = useColorModeValue('gray.200', 'gray.700'); 

  const scrollToSection = (sectionId: string) => {
    document.getElementById(sectionId)?.scrollIntoView({ behavior: 'smooth' });
    onClose();  // Close the drawer when a link is clicked
  };

  return (
    <Box bg={navBg} px={4} w="100vw" position="sticky" top={0} zIndex={1}>
      <Flex
        h={16}
        alignItems={'center'}
        justifyContent={'space-between'}
        px={4}
        w="full"
      >
        <IconButton
          ref={btnRef}
          display={{ base: 'block', md: 'none' }}
          onClick={onOpen}
          icon={<HamburgerIcon />}
          size="md"
          aria-label={'Toggle Navigation'}
        />
        <Heading
          as="h1"
          size="md"
          pt={'2'}
          pl={{base: '6', md: '0'}}
          letterSpacing={'tighter'}
          textAlign={{ base: 'center', md: 'left' }}
          flex={{ base: 1, md: 'none' }}
          fontFamily='Poppins'
        >
          MicahStewart.com
        </Heading>
        <Drawer
          isOpen={isOpen}
          placement='left'
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader fontFamily='Poppins' fontSize={22} borderBottomWidth='1px' textAlign="left">MicahStewart.com</DrawerHeader>
            <DrawerBody bg={menuBg}>
              <VStack
                as="nav"
                spacing={4}
                align="start"
                justify="center"
                w="full"
                fontFamily='Nunito'
              >
                <Link fontSize={18} w="full" p={2} textAlign="left" _hover={{ bg: hoverBg, textDecoration: 'none' }} onClick={() => scrollToSection('home')}>Home</Link>
                {/* <Link fontSize={18} w="full" p={2} textAlign="left" _hover={{ bg: hoverBg, textDecoration: 'none' }} onClick={() => scrollToSection('about')}>About</Link> */}
                <Link fontSize={18} w="full" p={2} textAlign="left" _hover={{ bg: hoverBg, textDecoration: 'none' }} onClick={() => scrollToSection('projects')}>Projects</Link>
                <Link fontSize={18} w="full" p={2} textAlign="left" _hover={{ bg: hoverBg, textDecoration: 'none' }} onClick={() => scrollToSection('contact')}>Contact</Link>
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        <Flex
          display={{ base: 'none', md: 'flex' }}
          ml={{ md: 10 }}
          fontFamily='Nunito'
          flex={1}
          justifyContent={'flex-end'}
        >
          <Link fontSize="lg" p={3} onClick={() => scrollToSection('home')} _hover={{ bg: useColorModeValue('gray.200', 'gray.700') }}>Home</Link>
          <Link fontSize="lg" p={3} onClick={() => scrollToSection('projects')} _hover={{ bg: useColorModeValue('gray.200', 'gray.700') }}>Projects</Link>
          {/* <Link fontSize="lg" p={3} onClick={() => scrollToSection('about')} _hover={{ bg: useColorModeValue('gray.200', 'gray.700') }}>About</Link> */}
          <Link fontSize="lg" p={3} onClick={() => scrollToSection('contact')} _hover={{ bg: useColorModeValue('gray.200', 'gray.700') }}>Contact</Link>
        </Flex>
        <ColorModeSwitcher ml={8} justifySelf="flex-end" />
      </Flex>
    </Box>
  );
};

export default Navbar;
