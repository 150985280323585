import React from "react";
import {
  Box,
  Text,
  Link,
  Flex,
  Stack,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaGithub, FaFacebook, FaLinkedin, FaEnvelopeSquare } from "react-icons/fa";

function FooterMenu() {
  const bgColor = useColorModeValue("gray.100", "gray.900");
  const textColor = useColorModeValue("gray.700", "gray.200");

  return (
    <Box bg={bgColor} color={textColor} mt={10} py={5}>
      <Flex
        direction={{ base: "column", md: "row" }}
        justify="space-between"
        align="center"
        maxW="1200px"
        mx="auto"
        px={6}
      >
        <Text fontSize="md" mt={{ base: 0, md: 4 }}>
          &copy; {new Date().getFullYear()} Micah Stewart. All Rights
          Reserved.
        </Text>
        
        <Stack
          direction="row"
          spacing={4}
          align="center"
          mt={{ base: 1, md: 0 }}
        >
          <Link href="https://github.com/Micahlou" isExternal>
            <Icon as={FaGithub} w={6} h={6} />
          </Link>
          <Link href="https://www.linkedin.com/in/micah-lou%C3%ADs-00a213228" isExternal>
            <Icon as={FaLinkedin} w={6} h={6} />
          </Link>
          <Link href="https://facebook.com" isExternal>
            <Icon as={FaFacebook} w={6} h={6} />
          </Link>
          <Link href="mailto:micahlouis99@gmail.com" isExternal>
            <Icon as={FaEnvelopeSquare} w={6} h={6} />
          </Link>
        </Stack>
      </Flex>
    </Box>
  );
}

export default FooterMenu;
