import {
  ChakraProvider,
  Box,
  Text,
  theme,
  Stack,
  Flex,
} from "@chakra-ui/react";
import { ProfileContent } from "./components/ProfileContent";
import { ProfileCard } from "./components/ProfileCard";
import { AboutCard } from "./components/AboutCard";
import Navbar from "./components/NavBar";
import MyProfilePic from "./assets/images/MyProfilePic.jpg";
import ProSummary from "./components/ProSummary";
import Experience from "./components/ExperienceCard";
import LeadForm from "./components/LeadForm";
import Chatbot from "./components/ChatBot";
import FooterMenu from "./components/FooterMenu";
import { InView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

// Define animation variants
const fadeInUp = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0 }
};

// Create motion components
const MotionFlex = motion(Flex);
const MotionBox = motion(Box);

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <MotionFlex direction="column" align="center" w="100vw" minH="100vh">
        <Stack id="home" spacing="0px">
          <Navbar />
          <InView triggerOnce={true} threshold={0.5}>
            {({ inView, ref }) => (
              <MotionFlex
                ref={ref}
                direction={{ base: "column", md: "row" }}
                align="center"
                justify="center"
                w="full"
                px={{ base: "2", md: "8" }}
                py={{ base: "4", md: "8" }}
                maxW="1920px"
                mx="auto"
                initial="hidden"
                animate={inView ? 'visible' : 'hidden'}
                variants={fadeInUp}
              >
                <ProfileCard>
                  <ProfileContent
                    role={"Full Stack Software Engineer"}
                    image={MyProfilePic}
                    name={"Micah Louís Stewart"}
                    linkedIn="https://www.linkedin.com/in/micah-lou%C3%ADs-00a213228/"
                    email="micahlouis99@gmail.com"
                  />
                </ProfileCard>
                <ProSummary />
              </MotionFlex>
            )}
          </InView>
          <InView triggerOnce={true} threshold={0.1}>
  {({ inView, ref }) => (
    <MotionBox
      ref={ref}
      id="projects"
      mx="auto"
      w="full"
      maxW="1920px"
      py={{ base: "8", md: "20" }}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
      variants={fadeInUp}
    >
      <AboutCard w="full" maxW="1920px">
        <Text  pl={2} fontFamily={"Verdana"} fontWeight="900" fontSize={{ base: "24px", md: "32px", lg: "38px", xl: "42px" }} textAlign={{base: "center", md: "center"}}  textShadow={{ base: "1px 1px 2px gray", md: "none" }}>
          My Recent Work Experience
        </Text>
        <Box display="flex" flexDirection="column">
          <Experience />
        </Box>
      </AboutCard>
    </MotionBox>
  )}
</InView>
          <InView triggerOnce={true} threshold={0.5}>
            {({ inView, ref }) => (
              <MotionBox
                ref={ref}
                id="contact"
                initial="hidden"
                animate={inView ? 'visible' : 'hidden'}
                variants={fadeInUp}
              >
                <LeadForm />
              </MotionBox>
            )}
          </InView>
          <Chatbot />
          <FooterMenu />
        </Stack>
      </MotionFlex>
    </ChakraProvider>
  );
};
