import { Box, Text } from "@chakra-ui/react";

const ProSummary = () => {
  return (
    <Box
      textAlign={{ base: "center", md: "left" }}
      maxW={{ base: "90%", md: "55%" }}
      pt={6}
      mx={{base: "auto", md: "unset"}}  // Center the box on all screen sizes
      pb={4}
    >
      <Text
        pl={{ base: 4, md: 3 }}
        fontFamily="Verdana"
        fontSize={{ base: "24px", md: "32px", lg: "38px", xl: "42px" }}  // Gradually increase font size for larger screens
        fontWeight="bold"
        borderBottom={{ base: "2px solid", md: "none" }}
        textShadow={{ base: "1px 1px 2px gray", md: "none" }}  // Lighter shadow on mobile for subtlety
        mb={2}
      >
        Professional Summary
      </Text>
      <Text
        px={4}  // Uniform horizontal padding
        py={2}  // Slight vertical padding for separation
        fontFamily="Tahoma"
        fontSize={{ base: "18px", md: "20px", lg: "24px" }}  // Smaller base size improving readability on mobile
        lineHeight="tall"  // Increase line height for better text readability
      >
        I am a Full Stack Software Engineer with a diverse background in both front-end and back-end technologies. My professional journey includes significant roles at Chewy, where I streamlined operations by implementing advanced automation, and Alley Cat Mobile, where I led the development of a highly rated user-centric mobile app.
        I excel in fast-paced environments, delivering both innovative solutions and enhancements to user interfaces and system architectures. My work has consistently resulted in increased efficiency, improved user engagement, and high-quality, maintainable code.
      </Text>
    </Box>
  );
};

export default ProSummary;
