import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Text,
  VStack,
  Image,
  Heading,
  useColorModeValue,
  Button,
  Flex,
  Link,
  Show,
} from "@chakra-ui/react";
import AlleyCat from "../assets/images/AlleyCat.jpg"
import Chewy from "../assets/images/Chewy.jpg";
import TheWhiteGreenCloth from "../assets/images/TheWhiteGreenCloth.jpg"
import Opti from "../assets/images/Opti.jpg"
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { SimpleGrid, Icon } from "@chakra-ui/react";
import { SiFirebase, SiJavascript } from "react-icons/si";
import { FaVuejs, FaJava, FaDatabase, FaReact, FaApple, FaShopify, FaHtml5, FaCss3 } from "react-icons/fa"; // Example icons
import { IconType } from "react-icons/lib";
import ThumbnailAlley from '../assets/images/ThumbnailAlley.jpg';
import ThumbnailChewy from '../assets/images/ThumbnailChewy.jpg';
import ThumbnailTheWhiteGreenCloth from '../assets/images/ThumbnailWhite.jpg';
import ThumbnailOpti from '../assets/images/ThumbnailOpti.jpg';

interface TechStackItem {
    name: string;
    icon: IconType; // Use IconType for icons
  }
  
  interface TechStackProps {
    techItems: TechStackItem[];
  }
  
  const TechStack: React.FC<TechStackProps> = ({ techItems }) => {
    const bgColor = useColorModeValue("whiteAlpha.900", "gray.800");
    const colorScheme = useColorModeValue("black", "whitesmoke");
    const boxShadow = useColorModeValue("base", "dark-lg");
  
    return (
      <Box maxW="container.lg" mx="auto" p={5}>
        <SimpleGrid columns={{ base: 3 }} spacing={5}>
          {techItems.map((item) => (
            <Box
              key={item.name}
              p={3}
              bg={bgColor}
              rounded="md"
              boxShadow={boxShadow}
              textAlign="center"
            >
              <Icon as={item.icon} w={10} h={10} color={colorScheme} />
              <Text mt={2}>{item.name}</Text>
            </Box>
          ))}
        </SimpleGrid>
      </Box>
    );
  };
interface ExperienceCardProps {
    title: string;
    role: string;
    techStack: TechStackItem[];
    children: React.ReactNode;
    repoLink?: string; // Optional if not all cards have a repo
    siteLink?: string;
    imageUrl: string;
    imageThumbnailUrl: string;
    description: string;
    scrollSpeed?: number;
  }

const ExperienceCard: React.FC<ExperienceCardProps> = ({
    title,
    role,
    techStack,
    children,
    repoLink,
    siteLink,
    imageUrl,
    imageThumbnailUrl,
    description,
    scrollSpeed = 3, // Default scroll speed if not provided
  }) => {
    const [currentImage, setCurrentImage] = useState(imageThumbnailUrl);
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const imageRef = useRef<HTMLImageElement>(null);
    const [translateY, setTranslateY] = useState("0%");
    const [transitionDuration, setTransitionDuration] = useState(`${scrollSpeed}s`);


    const handleMouseEnter = () => {
      setCurrentImage(imageUrl); // Change to the full image
      setIsImageLoaded(false); // Set that the full image is loaded
      if (imageRef.current) {
        imageRef.current.style.transition = `transform ${transitionDuration} ease-in-out 0.5s`;
    }
  };

    const handleMouseLeave = () => {
        setCurrentImage(imageThumbnailUrl); // Change back to thumbnail
        if (imageRef.current) {
            imageRef.current.style.transition = 'none'; // Remove any transition to ensure immediate change
        }
        // Delay the reset of isImageLoaded so it doesn't interfere with the transition reset
        setTimeout(() => setIsImageLoaded(false), 50);
    };

    useEffect(() => {
      if (imageRef.current && imageRef.current.parentNode instanceof HTMLElement) {
          const imageHeight = imageRef.current.clientHeight;
          const containerHeight = imageRef.current.parentNode.clientHeight;
          const percentage = ((imageHeight - containerHeight) / imageHeight) * 100;
          setTranslateY(`-${percentage}%`);

          // Adjust transition duration if image size changes
          setTransitionDuration(`${scrollSpeed}s`);
      }
    }, [imageRef.current?.clientHeight, isImageLoaded, scrollSpeed]); // Include isImageLoaded to recalculate on image load

    const cardBg = useColorModeValue("white", "#111");
    const textColor = useColorModeValue("gray.700", "gray.200");
    const buttonColorScheme = useColorModeValue("blue", "orange");

    return (
        <Box
          bg={cardBg}
          boxShadow="2xl"
          rounded="lg"
          p={4}
          mb={2}
          mt={2}
          overflow="hidden"
          position="relative"
          width={["100%", "100%", "100%", "100%"]} // Responsive width
          onMouseEnter={handleMouseEnter} 
          onMouseLeave={handleMouseLeave}
        >
          <Box
           height={{ base: "200px", md: "300px", lg: "200px", xl: "280px", '2xl': "350px" }}
            overflow="hidden"
            position="relative"
            _hover={{
              ".image-preview": {
                transform: isImageLoaded ? `translateY(${translateY})` : "translateY(0)" // Only transform if image is loaded
              }
            }}
          >
            <Image
              ref={imageRef}
              className="image-preview"
              src={currentImage}
              alt={`Image of ${title}`}
              htmlWidth="100%"
              htmlHeight="auto"
              loading="lazy" 
              objectFit="cover"
              
              onLoad={() => setIsImageLoaded(true)} // Set loaded to true when image finishes loading
            />
          </Box>
          <VStack spacing={3} align="left">
            <Heading fontSize="xl" mb={1} mt={8}>{title}</Heading>
            <Text fontSize={"18px"} fontWeight="semibold" color={textColor}>{role}</Text>
            <Show above="md"> {/* Only display TechStack on medium devices and above */}
          <TechStack techItems={techStack} />
        </Show>
            <Text fontSize={"16px"}>{description}</Text>
            <Text fontSize="sm" color={textColor}>
              {children}
            </Text>
            <Flex justifyContent="space-between" w="full">
              {repoLink && (
                <Button as={Link} href={repoLink} isExternal colorScheme={buttonColorScheme} leftIcon={<ExternalLinkIcon />} size="sm">
                  View Repo
                </Button>
              )}
              {siteLink && (
                <Button as={Link} href={siteLink} isExternal colorScheme={buttonColorScheme} leftIcon={<ExternalLinkIcon />} size="md">
                  View Site
                </Button>
              )}
            </Flex>
          </VStack>
        </Box>
      );
    };
  
  const Experience = () => {
    return (
        <Flex justify="center">
        <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={10} width="full">
        <ExperienceCard
          title="Chewy"
          role="Full Stack Software Engineer"
          imageUrl={Chewy}
          imageThumbnailUrl={ThumbnailChewy}
          siteLink="https://www.chewy.com/"
          techStack={[
            { name: "Vue.js", icon: FaVuejs },
            { name: "Java", icon: FaJava },
            { name: "PostgreSQL", icon: FaDatabase },
          ]}
          description="As a Full Stack Software Engineer at Chewy, I was actively involved in designing and implementing critical Fulfillment Support Applications to enhance operational efficiency in Chewy's fulfillment centers."
          scrollSpeed={5}
        >
        </ExperienceCard>
        <ExperienceCard
          title="Alley Cat Music Membership"
          role="Mobile Developer"
          imageUrl={AlleyCat}
          imageThumbnailUrl={ThumbnailAlley}
          siteLink="https://apps.apple.com/us/app/alley-cat-music-membership/id1360490393"
          techStack={[
            { name: "React Native", icon: FaReact },
            { name: "iOS Development", icon: FaApple },
            { name: "Firebase", icon: SiFirebase },
          ]}
          description="Spearheaded the development of the Alley Cat Music Membership app, utilizing React Native to craft a highly interactive and user-friendly platform aimed at music enthusiasts."
          scrollSpeed={3}
        >
        </ExperienceCard>
        <ExperienceCard
          title="The White Green Cloth LLC"
          role="Shopify Developer"
          imageUrl={TheWhiteGreenCloth}
          imageThumbnailUrl={ThumbnailTheWhiteGreenCloth}
          siteLink="https://thewhitegreencloth.com/"
          techStack={[
            { name: "Shopify Liquid", icon: FaShopify },
            { name: "HTML5", icon: FaHtml5 },
            { name: "CSS3", icon: FaCss3 },
          ]}
          description="As the lead developer and designer for a sophisticated Shopify e-commerce website, I managed the complete project lifecycle, from conceptualization and wireframing to development and deployment."
          scrollSpeed={5}
        >
        </ExperienceCard>
        <ExperienceCard
          title="Opti Integrations"
          role="Frontend Web Developer"
          imageUrl={Opti}
          imageThumbnailUrl={ThumbnailOpti}
          siteLink="https://optiintegrations.com/"
          techStack={[
            { name: "JavaScript", icon: SiJavascript },
            { name: "HTML5", icon: FaHtml5 },
            { name: "CSS3", icon: FaCss3 },
          ]}
          description="Developed an agency website designed to create effective websites, manage online leads, directory listings, boost online reviews, and facilitate appointment bookings aimed to help enhance business efficiency for small to large businesses."
          scrollSpeed={5}
        >
        </ExperienceCard>
        </SimpleGrid>
    </Flex>
    );
  };
  

export default Experience;
