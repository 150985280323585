import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import {
  FormControl,
  Input,
  Text,
  Textarea,
  Button,
  Flex,
  useToast,
  Box,
  Center,
  useColorModeValue,
} from '@chakra-ui/react';

function LeadForm() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  });


  const toast = useToast();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // EmailJS parameters
    const serviceID = 'service_1gmw155';  // Replace with your actual service ID from EmailJS
    const templateID = 'template_fyfwj72';  // Replace with your template ID
    const userID = 'FuUNYwk14sPwcf3VJ';  // Replace with your user ID from EmailJS

    const templateParams = {
      from_name: `${formData.firstName} ${formData.lastName}`, // Combine first and last name
      from_email: formData.email, // Ensure you collect and send the email
      message: formData.message,
    };

    try {
      const response = await emailjs.send(serviceID, templateID, templateParams, userID);
      console.log('SUCCESS!', response.status, response.text);
      toast({
        title: 'Message sent',
        description: 'Your message has been successfully sent!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      setFormData({ firstName: '', lastName: '', email: '', message: '' }); // Reset form
    } catch (err) {
      console.error('FAILED...', err);
      toast({
        title: 'Failed to send message',
        description: 'Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    };

    setFormData({
      firstName: '',
      lastName: '',
      email: '',
      message: '',
    });
  };

  const inputBg = useColorModeValue('white', 'gray.700');
  const inputColor = useColorModeValue('gray.900', 'whiteAlpha.900');
  const borderColor = useColorModeValue('gray.300', 'gray.600');
  const textColor = useColorModeValue('gray.900', 'white');

  return (
    <Box as="section" py={12} px={6} bg={useColorModeValue('gray.100', 'gray.900')}>
      <Center>
        <Box maxWidth="1200px" width="full">
        <Text fontSize="lg" fontWeight="bold" color={textColor} textAlign="center" fontFamily="Verdana">Get In Touch</Text>
          <Text fontSize="3xl" fontWeight="bold" textAlign="center" color={textColor} fontFamily="Verdana" mb={6}>Let's Work Together.</Text>
          <form onSubmit={handleSubmit}>
            <Flex direction={['column', 'row']} justify="space-between" align="center" wrap="wrap" gap={3}>
              <FormControl isRequired>
                <Input
                  id="firstName"
                  name="firstName"
                  type="text"
                  value={formData.firstName}
                  onChange={handleChange}
                  placeholder="First Name"
                  bg={inputBg}
                  color={inputColor}
                  borderColor={borderColor}
                />
              </FormControl>
              <FormControl isRequired>
                <Input
                  id="lastName"
                  name="lastName"
                  type="text"
                  value={formData.lastName}
                  onChange={handleChange}
                  placeholder="Last Name"
                  bg={inputBg}
                  color={inputColor}
                  borderColor={borderColor}
                />
              </FormControl>
              <FormControl isRequired>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                  bg={inputBg}
                  color={inputColor}
                  borderColor={borderColor}
                />
              </FormControl>
              <FormControl isRequired flex="1">
                <Textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Message"
                  bg={inputBg}
                  color={inputColor}
                  borderColor={borderColor}
                />
              </FormControl>
              <Button type="submit" colorScheme="blue" size="lg" px={10}>Send Message</Button>
            </Flex>
          </form>
        </Box>
      </Center>
    </Box>
  );
}

export default LeadForm;
