import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Button, Input, VStack, Text, useColorModeValue, Image, InputGroup, InputRightElement, IconButton, Link } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { AiOutlineSend } from "react-icons/ai";
import ChatMicah from "../assets/images/ChatMicah.png";

interface Message {
  text: string | JSX.Element;  // Allow JSX Element for links
  sender: 'bot' | 'user';
}

const ChatBot: React.FC = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [messages, setMessages] = useState<Message[]>([{ text: "Hi! I'm Micah's AI assistant. Ask me anything about Micah's work!", sender: 'bot' }]);
    const [input, setInput] = useState('');
    const [aiResponded, setAiResponded] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [keyboardActive, setKeyboardActive] = useState(false);  // State to handle keyboard visibility
    const endOfMessagesRef = useRef<HTMLDivElement>(null);
    const iconBgColor = useColorModeValue('red.500', 'red.500');
    const iconColor = useColorModeValue('white', 'white');
    const bgColor = useColorModeValue('white', 'gray.700');

    const handleResize = useCallback(() => {
        const viewportHeight = window.innerHeight;
        // threshold based on typical keyboard sizes
        if (viewportHeight < window.outerHeight * 0.3) {
            setKeyboardActive(true);
        } else {
            setKeyboardActive(false);
        }
    }, []);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    useEffect(() => {
        if (isOpen && endOfMessagesRef.current) {
            endOfMessagesRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [isOpen, messages]);

    const handleInputChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setInput(event.target.value);
    }, []);

    const handleSendClick = useCallback(() => {
        if (input.trim()) {
            const newUserMessage: Message = { text: input, sender: 'user' };
            setMessages(prevMessages => [...prevMessages, newUserMessage]);
            setInput('');
            if (!aiResponded) {
                setIsTyping(true);
                setTimeout(() => {
                    setMessages(prevMessages => [
                        ...prevMessages, 
                        { text: "We're sorry, AI Chat Capabilities are currently undergoing maintenance.", sender: 'bot' }
                    ]);
                    setTimeout(() => {
                        setMessages(prevMessages => [...prevMessages, {
                            text: (
                                <Text>
                                    If you'd like to get in touch with Micah you can reach him on <Link href="https://www.linkedin.com/in/micah-louis-stewart/" color="orange.200" textDecoration={"underline"} _hover={{ color: "orange.300" }} isExternal>LinkedIn</Link> or via the contact information here on the website.
                                </Text>
                            ), sender: 'bot'
                        }]);
                        setAiResponded(true); // Ensures the response is only sent once
                        setIsTyping(false);
                    }, 2000); // Time for typing animation
                }, 1000); // Time until maintenance message is shown
            }
        }
    }, [input, aiResponded]);

    const toggleChat = useCallback(() => {
        setIsOpen(prevState => {
            if (!prevState) { // If opening the chat
                setAiResponded(false); // Reset response state
            }
            return !prevState;
        });
    }, []);

    // Adjust the bottom position of the chat box based on keyboard visibility
    const chatBoxPosition = keyboardActive ? "20px" : "0";

    return (
        <Box position="fixed" bottom={chatBoxPosition} right="0" m={4} zIndex="1000">
            <Button onClick={toggleChat} bg="transparent" _hover={{ bg: "transparent" }} _active={{ bg: "transparent" }} _focus={{ outline: "none", boxShadow: "none" }} position="absolute" bottom="5" right="-50px" transform="translateX(-50%)" aria-label="Toggle chat">
                {isOpen ? <CloseIcon fontSize="60px" bg={iconBgColor} color={iconColor} borderRadius="full" p={2} border="2px" borderColor="red.600" /> : '' }
            </Button>
            {!isOpen && (
               <Button onClick={toggleChat} bg="transparent" _hover={{ bg: "transparent" }} _active={{ bg: "transparent" }} _focus={{ outline: "none", boxShadow: "none" }}>
                   <Image src={ChatMicah} alt="Chat with Micah" maxWidth={"100px"} position="absolute" bottom="0" right="0" />
               </Button>
            )}
            {isOpen && (
                <VStack bg={bgColor} p={4} boxShadow="md"  borderRadius="lg" spacing={4} width="350px" height="475px" position="relative" bottom="85px">
                    <Text fontSize="xl" fontWeight="bold" borderBottom="solid 1px" w="full" textAlign="center" pb={2}>MicahStewart.com</Text>
                    <VStack overflowY="auto" maxHeight="315px" spacing={4} align="start" w="full">
                        {messages.map((msg, index) => (
                            <Box key={index} alignSelf={msg.sender === 'bot' ? 'flex-start' : 'flex-end'} bg={msg.sender === 'bot' ? 'blue.500' : 'green.500'} color="white" p={2} borderRadius="md" maxWidth="80%" style={{ display: 'inline-block' }}>
                                <Text fontSize="md" mb={1}>{msg.text}</Text>
                            </Box>
                        ))}
                          {isTyping && <Text fontSize="md" color="gray.500">Typing...</Text>}
                        <div ref={endOfMessagesRef} />
                    </VStack>
                    <InputGroup mt="auto">
                        <Input 
                            pr="4.5rem" // Ensure padding to prevent text from overlapping the send button
                            placeholder="Type your message..." 
                            value={input} 
                            onChange={handleInputChange} 
                            onKeyPress={(event) => event.key === 'Enter' && handleSendClick()}
                        />
                        <InputRightElement>
                            <IconButton
                                aria-label="Send message"
                                icon={<AiOutlineSend />}
                                fontSize="28px"
                                onClick={handleSendClick}
                                variant="unstyled"
                            />
                        </InputRightElement>
                    </InputGroup>
                </VStack>
            )}
        </Box>
    );
};

export default ChatBot;
