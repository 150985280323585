import { Box, BoxProps, Flex, useColorModeValue } from "@chakra-ui/react";
import * as React from "react";

export const ProfileCard = (props: BoxProps) => (
  <Flex direction="column" align="left" textAlign="left">
    <Box
      bg={useColorModeValue("white", "gray.800")}
      maxWidth="lg"
      minWidth={"-moz-fit-content"}
      mx="auto"
      p={{ base: "6", md: "6" }}
      rounded={{ sm: "lg" }}
      {...props}
    />
  </Flex>
);
